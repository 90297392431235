import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import OurSolutions from "../components/Index/OurSolutions"
import MenuSlide from "../components/Index/Menuslide"
import { Helmet } from "react-helmet"
//import OurServices from "../components/Index/OurServices"
//import OurFeatures from "../components/Index/OurFeatures"
//import TeamMember from "../components/Index/TeamMember"
//import RecentProjects from "../components/Index/RecentProjects"
//import Pricing from "../components/Index/Pricing"
//import Testimonials from "../components/Index/Testimonials"
//import Partner from "../components/Index/Partner"
//import ProjectStartArea from "../components/Index/ProjectStartArea"
//import OurBlog from "../components/Index/OurBlog"

const Home = () => {
  return (
    <Layout>
      <Helmet>
        <title>Bumi Nusantara Group</title>
        <meta charSet='utf-8' />
        <meta name='title' content='Bumi Nusantara Group' />
        <meta name="description" content="Bumi Nusantara Group" />
        <meta name='keywords' content='Bumi Nusantara Group, Bumi Nusantara Jaya, Bumi Nusantara Bara, Maharaja Mineral, Samudra Ulam Nusantara, Ulam Laut Nusantara, Energi, Batu Bara, Biji Besi, Nikel, Ulam, Iron Ore, BNJ, BNG, BNB, MRM, FA, Mining, Trading, Farindo Agung, Binaia Energi Nusantara, Ulam Nusantara Abadi, Ulam Laut Nusantara, Samudra Ulam Nusantara, Berkat Kelautan Nusantara, Sinar Purefoods, Maha Raja Energi, Adimitra Hidro Nusantara, Arta Samudra Petroleum, Tiara Bumi Petroleum, Selera Maharaja Nusantara, Sikunci Sigaret Srintil, Sungai Indah Investama, Sungai Indah Artha Mulia, Sungai Indah Gemilang Lokantara, Sungai Indah Kencana Utama, Bumi Nusantara Armada, Elpi Nusantara Armada, Ulam Armada Nusantara, Kapal Sakti, Fiesheries, Tuna, Ikan Tuna, Ulam Nusantara Abadi, Ulam Laut Nusantara, Samudra Ulam Nusantara, Berkat Kelautan Nusantara, Sinar Purefoods, Rokok Kapal Sakti' />
        <meta name='author' content='BNG' />
        <meta property='og:locale' content='en_US' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://buminusa.co.id' />
        <meta property='og:url' content='https://buminusagroup.co.id' />
        <meta property='og:site_name' content='Bumi Nusantara Group' />
        <meta property='article:publisher' content='Get info PT. Maharaja Nusantara Energi' />
        <meta property='og:title' content='Bumi Nusantara Group' />
        <meta property='og:description' content='Welcome Bumi Nusantara Group' />
        <meta name="og:title" property="og:title" content="Bumi Nusantara Group"></meta>
        <meta name="twitter:card" content="Bumi Nusantara Group"></meta>
        <link rel="canonical" href="https://buminusa.co.id"></link>
        <link rel='canonical' href='https://buminusagroup.co.id'></link>
        <meta property="og:image" content="https://buminusagroup.co.id/upload/bnjgroup.png" />
      </Helmet>
      <Navbar />
      <Banner />
      <OurSolutions />
      <MenuSlide/>
      {/*<OurServices />
      <OurFeatures />
      <TeamMember />
      <RecentProjects />
      <Pricing />
    <Testimonials /
  <Partner />*/}
      {/*<OurBlog />
      <ProjectStartArea />*/}
      <Footer />
    </Layout>
  )
}

export default Home