import React from 'react'
import ReactWOW from 'react-wow'
//import { Link } from 'gatsby'
import bannerImg from '../../assets/images/banner-img1.png'
import circleImg from '../../assets/images/circle.png';
import VideoBg from '../../assets/images/Homepage.mp4';
import VideoBg1 from '../../assets/images/vid-bg.mp4';





const Banner = () => {
    return (
        <>
        <video className='bg-vid' autoPlay loop muted>
            <source src={VideoBg} type='video/mp4'/>
        </video>
        </>
    )
}

export default Banner